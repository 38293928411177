body,
html {
  font-size: 62.5%; /* = 10px */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body * {
  box-sizing: border-box;
}

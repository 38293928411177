@layer Common {
  .Code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .Scrollbar {
    /* only for Firefox */
    --scrollbar-transition: scrollbar-color var(--theme-transition-time);
    scrollbar-width: thin;
    scrollbar-color: var(--scroll) transparent;
    transition: var(--scrollbar-transition);
  }
  .Scrollbar::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  .Scrollbar::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: var(--scroll);
  }
  .Scrollbar::-webkit-scrollbar-corner,
  .ScrollbarHidden::-webkit-scrollbar-corner {
    display: none;
  }

  .ScrollbarHidden {
    scrollbar-color: transparent transparent;
  }
  .ScrollbarHidden::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .NoTapHighlight,
  .Button {
    -webkit-tap-highlight-color: transparent;
  }

  .Button {
    padding: 0.5rem;
    width: fit-content;
    user-select: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.5s, color var(--theme-transition-time);
    color: var(--text);
    background-color: var(--button);
  }
  .Button:hover {
    background-color: var(--button-hover);
  }
  .Button:active,
  .ButtonSelected {
    background-color: var(--button-active);
  }

  .ButtonDisabled {
    cursor: not-allowed !important;
    background-color: var(--button-disable) !important;
  }

  .Box {
    border-radius: 5px;
    cursor: pointer;
    transition: var(--theme-transition);
  }
  .Box:hover {
    transition: color 0.6s, background-color 0.6s;
    background-color: var(--background);
  }
}
